const tw = require('rev.sdk.js/Locale/tw');

const labels = {
  ...tw.labels,
  intro: '介紹',
  spec: '規格',
  remark: '備註',
  share_link: '複製分享連結',
  confirm: '確認',
  customer_support: '聯絡我們',
  customer_support_title: '問題類型',
  customer_support_content: '詢問內容',
  name: '姓名',
  phone: '聯絡電話',
  email: '信箱',
  updated: '更新時間',
  next_article: '上一篇',
  prev_article: '下一篇',
  cart: '購物車',
  cart_add: '加入購物車',
  cart_add_error_message_items_limit: '超過購物車購買上限，請分批購買',
  cart_add_error_message: '發生錯誤, 請至個人頁面按下「清除購物車」再重新嘗試',
  cart_clear: '清除購物車',
  cart_clear_success: '清除購物車成功',
  cart_clear_failure: '清除購物車失敗，請與客服人員聯繫',
  cart_empty_content: '購物車尚無商品！歡迎選購加入購物車！',
  buy_now: '立即購買',
  checkout_info: '寄送資訊',
  cart_empty: 'Cart Empty',
  sale_start_time: '供應開始時間',
  sale_end_time: '供應結束時間',
  all_categories: '全部',

  // NB
  MainPage: '首頁',
  Announcement: '公告',
  Product: '商品',
  Member: '會員',
  Video: '影片',
  Setting: '設定',
  Logout: '登出',
  nav_news: '最新消息',
  nav_products: '商品型錄',
  nav_videos: '影片資訊',
  nav_profile: '個人資訊',
  nav_reservations: '預購清單',
  please_choose_page: '請選擇控制頁面',
  dealers_login_first: '經銷商請先登入',
  title: '標題',
  label: '類別',
  created: '創建時間',
};

module.exports = {labels};
